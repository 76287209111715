import React, { Component } from 'react'
import { ProposalDetailPageContainer } from '../containers'

class ProposalDetailPage extends Component {
    render() {
        return <ProposalDetailPageContainer />
    }
}

export default ProposalDetailPage
