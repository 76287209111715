import React, { Component } from 'react'
import { GovernancePageContainer } from '../containers'

class GovernancePage extends Component {
    render() {
        return <GovernancePageContainer />
    }
}

export default GovernancePage
