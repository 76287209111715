import React, { Component } from 'react'
import { NotFoundPageContainer } from '../containers'

class NotFoundPage extends Component {
    render() {
        return <NotFoundPageContainer />
    }
}

export default NotFoundPage
