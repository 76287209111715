import React, { Component } from 'react'
import { TransactionDetailPageContainer } from '../containers'

class TransactionDetailPage extends Component {
    render() {
        return <TransactionDetailPageContainer />
    }
}

export default TransactionDetailPage
