import React, { Component } from 'react'
import { AddressDetailPageContainer } from '../containers'

class AddressDetailPage extends Component {
    render() {
        return <AddressDetailPageContainer />
    }
}

export default AddressDetailPage
