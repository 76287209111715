import React, { Component } from 'react'
import { TxPageContainer } from '../containers'

class TxPage extends Component {
    render() {
        return <TxPageContainer />
    }
}

export default TxPage
