import React, { Component } from 'react'
import { BlockListPageContainer } from '../containers'

class BlockListPage extends Component {
    render() {
        return <BlockListPageContainer />
    }
}

export default BlockListPage
