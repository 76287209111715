import React, { Component } from 'react'
import { ProposalSubmitPageContainer } from '../containers'

class ProposalSubmitPage extends Component {
    render() {
        return <ProposalSubmitPageContainer />
    }
}

export default ProposalSubmitPage
