import React, { Component } from 'react'
import { TokenListPageContainer } from '../containers'

class TokenListPage extends Component {
    render() {
        return <TokenListPageContainer />
    }
}

export default TokenListPage
